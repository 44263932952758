
import { ModelStructurePanel } from "./ModelStructurePanel";
import * as et from "../application/EventTypes";
import * as se from "./controls/SearchEvents";
import { Searchbox } from "./controls/Searchbox";
import { logger } from "../logger/Logger";
import { ViewerPanelMixin } from "./ViewerPanelMixin";

var avp = Autodesk.Viewing.Private;

var kDefaultDocStructureConfig = {
  "click": {
    "onObject": ["isolate"] },

  "clickShift": {
    "onObject": ["toggleMultipleOverlayedSelection"] },

  "clickCtrl": {
    "onObject": ["toggleVisibility"] } };



export function ViewerModelStructurePanel(viewer, title, options) {

  this.viewer = viewer;
  this.visible = false;
  this._trackNodeClick = true; // ADP

  options = options || {};
  options.defaultTitle = "Model";
  options.excludeRoot = options.excludeRoot !== undefined ? options.excludeRoot : true;
  options.startCollapsed = options.startCollapsed !== undefined ? options.startCollapsed : false;
  options.scrollEaseCurve = options.scrollEaseCurve || [0, 0, .29, 1];
  options.scrollEaseSpeed = options.scrollEaseSpeed !== undefined ? options.scrollEaseSpeed : 0.003; // 0 disables interpolation.
  options.addFooter = options.addFooter !== undefined ? options.addFooter : true;

  this.clickConfig = options && options.docStructureConfig ? options.docStructureConfig : kDefaultDocStructureConfig;
  this.isMac = navigator.userAgent.search("Mac OS") !== -1;

  if (options.hideSearch) {
    options.heightAdjustment = 70;
    ModelStructurePanel.call(this, viewer.container, viewer.container.id + 'ViewerModelStructurePanel', title, options);
    this.scrollContainer.classList.add('no-search');
  } else {
    options.heightAdjustment = 104; //bigger than default because of search bar
    ModelStructurePanel.call(this, viewer.container, viewer.container.id + 'ViewerModelStructurePanel', title, options);

    this.searchbox = new Searchbox(viewer.container.id + 'ViewerModelStructurePanel' + '-Searchbox', viewer, { excludeRoot: options.excludeRoot, searchFunction: filterIds.bind(this) });
    this.searchbox.setGlobalManager(this.globalManager);
    this.searchbox.addEventListener(se.ON_SEARCH_SELECTED, function (event) {
      var dbId = event.id;
      var model = this.viewer.impl.findModel(event.modelId);
      this.viewer.isolate(dbId, model);
    }.bind(this));
    this.container.appendChild(this.searchbox.container);
  }
  this.setGlobalManager(viewer.globalManager);


  this._ignoreScroll = false;

  this.selectedNodes = {};

  this.onViewerSelect = this.onViewerSelect.bind(this);
  this.onViewerIsolate = this.onViewerIsolate.bind(this);
  this.onViewerHide = this.onViewerHide.bind(this);
  this.onViewerShow = this.onViewerShow.bind(this);
};

ViewerModelStructurePanel.prototype = Object.create(ModelStructurePanel.prototype);
ViewerModelStructurePanel.prototype.constructor = ViewerModelStructurePanel;
ViewerPanelMixin.call(ViewerModelStructurePanel.prototype);

/**
                                                             * Invoked when the panel is getting destroyed.
                                                             */
ViewerModelStructurePanel.prototype.uninitialize = function () {
  if (this.viewer) {
    this.viewer.removeEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.onViewerSelect);
    this.viewer.removeEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.onViewerIsolate);
    this.viewer.removeEventListener(et.HIDE_EVENT, this.onViewerHide);
    this.viewer.removeEventListener(et.SHOW_EVENT, this.onViewerShow);
    this.viewer = null;
  }
  if (this.searchResults) {
    this.searchResults.uninitialize();
    this.searchResults = null;
  }
  ModelStructurePanel.prototype.uninitialize.call(this);
};

ViewerModelStructurePanel.prototype.resizeToContent = function () {

  var treeNodesContainer = this.scrollContainer;
  var rootContainer = this.tree ? this.tree.getRootContainer() : null;

  if (!treeNodesContainer || !rootContainer) {
    return;
  }

  var size = 'calc(100% + ' + treeNodesContainer.scrollLeft + 'px)';
  rootContainer.style.width = size;
};

ViewerModelStructurePanel.prototype.createUI = function () {

  if (this.uiCreated) {
    return;
  }

  var viewer = this.viewer;
  ModelStructurePanel.prototype.createUI.call(this);

  // Get container of the tree nodes, also, set its scrollbar to the left.
  var treeNodesContainer = this.scrollContainer;
  treeNodesContainer.classList.remove('left');

  // This method will resize panel according to content each frame, we could implement this in clever and more complicated way
  // but with the risk to not contemplating all the cases resizing is needed.
  var onResize = function () {
    if (this.visible) {
      this.resizeToContent();
    }
    requestAnimationFrame(onResize);
  }.bind(this);
  onResize();

  // Set position and height.
  var options = this.options;
  var toolbarSize = 0;
  var margin = 10;
  var viewerRect = viewer.container.getBoundingClientRect();
  var toolbar = viewer.getToolbar();
  var maxHeight = options.maxHeight ? options.maxHeight : viewerRect.height;

  if (toolbar) {
    var toolbarRect = toolbar.container.getBoundingClientRect();
    toolbarSize = viewerRect.bottom - toolbarRect.top;
  } else {
    toolbarSize = 0;
  }

  this.container.style.top = margin + 'px';
  this.container.style.left = margin + 'px';
  this.container.style.height = 'calc(100% - ' + (toolbarSize + margin * 2) + 'px)';
  this.container.style.maxHeight = 'calc(100% - ' + (toolbarSize + margin * 2) + 'px)';

  // Show context menu on right click over the panel.
  treeNodesContainer.addEventListener('contextmenu', function (event) {
    this.viewer.contextMenu.show(event);
  }.bind(this));

  // When selection changes in the viewer, the tree reflects the selection.
  this.viewer.addEventListener(et.AGGREGATE_SELECTION_CHANGED_EVENT, this.onViewerSelect);
  this.viewer.addEventListener(et.AGGREGATE_ISOLATION_CHANGED_EVENT, this.onViewerIsolate);
  this.viewer.addEventListener(et.HIDE_EVENT, this.onViewerHide);
  this.viewer.addEventListener(et.SHOW_EVENT, this.onViewerShow);
};

/**
    * Viewer Event handler
    * @private
    */
ViewerModelStructurePanel.prototype.onViewerSelect = function (event) {
  this.setSelection(event.selections);
  if (!this._ignoreScroll) {
    this.scrollToSelection(event.selections);
  }
  this._ignoreScroll = false;
};

/**
    * Viewer Event handler
    * @private
    */
ViewerModelStructurePanel.prototype.onViewerIsolate = function (event) {
  this.setIsolation(event.isolation);
};

/**
    * Viewer Event handler
    * @private
    */
ViewerModelStructurePanel.prototype.onViewerHide = function (event) {
  this.setHidden(event.nodeIdArray.slice(), event.model, true);
};

/**
    * Viewer Event handler
    * @private
    */
ViewerModelStructurePanel.prototype.onViewerShow = function (event) {
  this.setHidden(event.nodeIdArray.slice(), event.model, false);
};



ViewerModelStructurePanel.prototype.setVisible = function (show) {

  ModelStructurePanel.prototype.setVisible.call(this, show);

  if (this.visible === show) {
    return;
  }

  this.visible = show;

  if (this.visible) {
    this.sync();
  }
};

ViewerModelStructurePanel.prototype.sync = function () {

  var isolation = this.viewer.getAggregateIsolation();
  this.setIsolation(isolation);

  if (isolation.length === 0) {
    var hidden = this.viewer.getAggregateHiddenNodes();
    for (var i = 0; i < hidden.length; ++i) {
      var model = hidden[i].model;
      var ids = hidden[i].ids;
      this.setHidden(ids, model, true);
    }
  }

  var selection = this.viewer.getAggregateSelection();
  this.setSelection(selection);
  this.scrollToSelection(selection);
};

ViewerModelStructurePanel.prototype.removeTreeUI = function (model) {

  delete this.selectedNodes[model.id];
  ModelStructurePanel.prototype.removeTreeUI.call(this, model);
};

ViewerModelStructurePanel.prototype.setHidden = function (nodes, model, hidden) {

  var tree = this.tree;
  var delegate = tree.getDelegate(model.id);

  var action = hidden ?
  function (node) {
    tree.addClass(delegate, node, 'dim', false);
    tree.removeClass(delegate, node, 'visible', false);
    return true;
  } :
  function (node) {
    tree.removeClass(delegate, node, 'dim', false);
    tree.addClass(delegate, node, 'visible', false);
    return true;
  };

  for (var i = 0; i < nodes.length; ++i) {
    tree.iterate(delegate, nodes[i], action);
  }
};

ViewerModelStructurePanel.prototype.setIsolation = function (isolation) {

  // Special case, nothing isolated when array is empty
  if (isolation.length === 0) {
    var tree = this.tree;
    tree.forEachDelegate(function (delegate) {

      var model = delegate.model;
      var instanceTree = delegate.instanceTree;

      if (!instanceTree)
      return;

      var rootId = instanceTree.getRootId();

      tree.iterate(delegate, rootId, function (node) {
        tree.removeClass(delegate, node, 'dim', false);
        tree.removeClass(delegate, node, 'visible', false);
        return true;
      });
      this.setHidden([rootId], model, false);
    }.bind(this));

    return;
  }

  // append missing models into the isolation array
  var fullyHidden = [];
  if (isolation.length) {
    this.tree.forEachDelegate(function (delegate) {
      var idx = -1;
      for (var j = 0; j < isolation.length; j++) {
        if (isolation[j].model === delegate.model) {
          idx = j;
          break;
        }
      }
      if (idx === -1) {
        fullyHidden.push(delegate);
      }
    }.bind(this));
  }


  // Process isolation
  for (var i = 0; i < isolation.length; ++i) {

    var model = isolation[i].model;
    var instanceTree = model.getData().instanceTree;
    if (!instanceTree) {
      continue;
    }
    var rootId = instanceTree.getRootId();

    var tree = this.tree;
    var delegate = tree.getDelegate(model.id);

    tree.iterate(delegate, rootId, function (node) {
      tree.removeClass(delegate, node, 'dim', false);
      tree.removeClass(delegate, node, 'visible', false);
      return true;
    });

    var nodes = isolation[i].ids;
    if (nodes.length === 0)
    continue;

    // If the root is isolated, we don't want to dim anything.
    //
    if (nodes.length === 1 && nodes[0] === rootId) {
      return;
    }

    this.setHidden([rootId], model, true);
    this.setHidden(nodes, model, false);
  }

  // Hide the rest of the models
  for (var i = 0; i < fullyHidden.length; ++i) {

    var tree = this.tree;
    var delegate = fullyHidden[i];
    var model = delegate.model;
    var instanceTree = delegate.instanceTree;
    if (!instanceTree) {
      continue;
    }
    var rootId = instanceTree.getRootId();

    tree.iterate(delegate, rootId, function (node) {
      tree.removeClass(delegate, node, 'dim', false);
      tree.removeClass(delegate, node, 'visible', false);
      return true;
    });

    this.setHidden([rootId], model, true);
  }
};

/**
    * Displays the given nodes as selected in this panel.
    *
    * @param {Array} nodes - An array of Autodesk.Viewing.Model nodes to display as selected
    */
ViewerModelStructurePanel.prototype.setSelection = function (aggregatedSelection)
{
  var i, k, parent, model, nodes, delegate, instanceTree;
  var tree = this.tree;

  // Un-mark the ancestors.
  //
  var scene = this.viewer.impl.modelQueue();
  for (var modelId in this.selectedNodes) {

    model = scene.findModel(parseInt(modelId));
    nodes = this.selectedNodes[modelId];
    delegate = tree.getDelegate(modelId);
    if (!delegate)
    continue;

    instanceTree = delegate.instanceTree;
    if (!instanceTree)
    continue;

    for (k = 0; k < nodes.length; ++k) {
      parent = instanceTree.getNodeParentId(nodes[i]);
      while (parent) {
        tree.removeClass(delegate, parent, 'ancestor-selected');
        parent = instanceTree.getNodeParentId(parent);
      }
    }

    tree.clearSelection(delegate);
  }

  // Mark the ancestors of the newly selected nodes.
  //
  this.selectedNodes = {};
  for (i = 0; i < aggregatedSelection.length; ++i) {
    model = aggregatedSelection[i].model;
    nodes = aggregatedSelection[i].dbIdArray || aggregatedSelection[i].selection;

    delegate = tree.getDelegate(model.id);
    if (!delegate)
    continue;

    instanceTree = delegate.instanceTree;
    if (!instanceTree)
    continue;

    for (k = 0; k < nodes.length; ++k) {
      parent = instanceTree.getNodeParentId(nodes[i]);
      while (parent) {
        tree.addClass(delegate, parent, 'ancestor-selected');
        parent = instanceTree.getNodeParentId(parent);
      }
    }

    // Mark the newly selected nodes.
    //
    tree.setSelection(delegate, nodes);

    // Bookkeeping
    this.selectedNodes[model.id] = nodes.concat();
  }
};



ViewerModelStructurePanel.prototype.scrollToSelection = function (aggregatedSelection) {

  // Grab first selection...
  var first = aggregatedSelection[0];
  if (!first)
  return;

  var model = first.model;
  var nodes = first.dbIdArray || first.selection;

  var scrollY = this.tree.scrollTo(nodes[0], model);

  var currScroll = this.scrollContainer.scrollTop;
  this.scrollContainer.scrollTop = scrollY;
  var endScroll = this.scrollContainer.scrollTop; // scrollTop will get modified due to height constraints.
  this.scrollContainer.scrollTop = currScroll;

  if (this.options.scrollEaseSpeed > 0) {
    this.animateScroll(currScroll, endScroll, function (posY) {
      this.tree.setScroll(posY);
    }.bind(this));
  } else {
    this.scrollContainer.scrollTop = endScroll;
    this.tree.setScroll(endScroll);
  }
};

/**
    * Invoked by our specialized delegate.
    */
ViewerModelStructurePanel.prototype.onEyeIcon = function (dbId, model) {

  this.viewer.toggleVisibility(dbId, model);
  avp.analytics.track('viewer.model_browser', {
    from: 'Panel',
    action: 'Toggle Visibility' });

};

/**
    * Overrides method in base class
    */
ViewerModelStructurePanel.prototype.onTreeNodeClick = function (tree, node, model, event)
{
  if (this._trackNodeClick) {
    logger.track({ category: 'node_selected', name: 'model_browser_tool' });
    this._trackNodeClick = false;
  }

  if (this.isMac && event.ctrlKey) {
    return;
  }

  var key = "click";
  if (this.ctrlDown(event)) {
    key += "Ctrl";
  }
  if (event.shiftKey) {
    key += "Shift";
  }
  if (event.altKey) {
    key += "Alt";
  }

  var actions = ['toggleOverlayedSelection'];
  var clickConfig = this.clickConfig[key];
  if (clickConfig) {
    actions = clickConfig["onObject"];
  }

  avp.analytics.track('viewer.model_browser', {
    from: 'Panel',
    action: 'Select' });

  this.handleAction(actions, node, model);
};

/**
    * Overrides method in base class
    */
ViewerModelStructurePanel.prototype.onTreeNodeRightClick = function (tree, node, model, event)
{
  // Sometimes CTRL + LMB maps to a right click on a mac. Redirect it.
  if (this.isMac && event.ctrlKey && event.button === 0) {
    if (this.clickConfig && this.clickConfig["clickCtrl"]) {
      this.handleAction(this.clickConfig["clickCtrl"]["onObject"], node, model);
    }
    return null;
  }

  return this.viewer.contextMenu.show(event);
};

/**
    * @private
    */
ViewerModelStructurePanel.prototype.handleAction = function (actionArray, dbId, model) {

  for (var action in actionArray) {
    switch (actionArray[action]) {
      case "toggleOverlayedSelection":
        this.toggleOverlayedSelection(dbId, model);
        break;
      case "toggleMultipleOverlayedSelection":
        this.toggleMultipleOverlayedSelection(dbId, model);
        break;
      case "selectOnly":
        this.viewer.select(dbId, model);
        break;
      case "deselectAll":
        this.viewer.clearSelection();
        break;
      case "selectToggle":
        this.viewer.toggleSelect(dbId, model);
        break;
      case "isolate":
        this.isolate(dbId, model);
        break;
      case "showAll":
        this.viewer.showAll();
        break;
      case "focus":
        this.viewer.fitToView();
        break;
      case "hide":
        this.viewer.hide(dbId, model);
        break;
      case "show":
        this.viewer.show(dbId, model);
        break;
      case "toggleVisibility":
        this.viewer.toggleVisibility(dbId, model);
        break;}

  }
};


/**
    * Click handler.
    */
ViewerModelStructurePanel.prototype.toggleOverlayedSelection = function (dbId, model) {

  var modelSelection = this.selectedNodes[model.id];
  var index = modelSelection ? modelSelection.indexOf(dbId) : -1;
  this._ignoreScroll = true;
  if (index === -1) {
    this.viewer.select(dbId, model);
    this.viewer.fitToView([dbId], model, false);
  } else {
    this.viewer.select([], undefined, model);
  }
};


/**
    * Shift Click handlers
    */
ViewerModelStructurePanel.prototype.toggleMultipleOverlayedSelection = function (dbId, model)
{
  var modelSelection = this.selectedNodes[model.id];
  var index = modelSelection ? modelSelection.indexOf(dbId) : -1;
  if (index === -1) {
    if (!modelSelection) {
      modelSelection = this.selectedNodes[model.id] = [];
    }
    modelSelection.push(dbId);
  } else {
    modelSelection.splice(index, 1);
  }

  var selection = [];
  for (var modelId in this.selectedNodes) {
    if (this.selectedNodes.hasOwnProperty(modelId)) {
      var model = this.viewer.impl.findModel(parseInt(modelId));
      var ids = this.selectedNodes[modelId];
      selection.push({
        model: model,
        ids: ids });

    }
  }
  this._ignoreScroll = true;
  this.viewer.impl.selector.setAggregateSelection(selection);

  var aggregatedSelection = this.viewer.getAggregateSelection();
  this.viewer.fitToView(aggregatedSelection);
};

/**
    * @private
    */
ViewerModelStructurePanel.prototype.isolate = function (dbId, model) {
  this.viewer.isolate(dbId, model);
  this.viewer.fitToView([dbId], model, false);

  // fire show properties event
  if (model) {
    var event = {
      type: et.SHOW_PROPERTIES_EVENT,
      dbId: dbId,
      model: model };

    this.viewer.dispatchEvent(event);
  }
};

/**
    * @private
    */
ViewerModelStructurePanel.prototype.ctrlDown = function (event) {
  return this.isMac && event.metaKey || !this.isMac && event.ctrlKey;
};

/**
    * 
    * @param {*} text 
    * 
    * @returns Array with objects containing { delegate:Delegate, ids:Array }
    */
function filterIds(text) {

  var tree = this.tree;
  var searchTerm = text.toLowerCase();
  var result = [];

  tree.forEachDelegate(function (delegate) {
    var rootId = delegate.getRootId();
    var ids = [];
    tree.iterate(delegate, rootId, function (id) {
      var idName = delegate.instanceTree && delegate.instanceTree.getNodeName(id);
      if (idName && idName.toLowerCase().indexOf(searchTerm) !== -1) {
        ids.push(id);
      }
      return true;
    });

    result.push({ ids: ids, delegate: delegate });
  });

  avp.analytics.track('viewer.model_browser', {
    from: 'Panel',
    action: 'Search' });


  return result;
}